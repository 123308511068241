export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_SPORT = 'DELETE_SPORT';
export const DELETE_CLUB = 'DELETE_CLUB';
export const DELETE_TRAINER = 'DELETE_TRAINER';
export const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE';
export const DELETE_CLUB_TRAINER = 'DELETE_CLUB_TRAINER';
export const DELETE_CLUB_SPORT = 'DELETE_CLUB_SPORT';
export const DELETE_CLUB_BRANCH = 'DELETE_CLUB_BRANCH';
export const DELETE_TRAINER_SPORT = 'DELETE_TRAINER_SPORT';
export const DELETE_SLIDER = 'DELETE_SLIDER';
export const DELETE_SPONSOR = 'DELETE_SPONSOR';
export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_SPECIAL_AD = 'DELETE_SPECIAL_AD';
export const DELETE_OFFER = 'DELETE_OFFER';
export const DELETE_PAGE = 'DELETE_PAGE';
