import {
  FETCH_CLUBS,
  FETCH_CLUB_BRANCHES,
  FETCH_CLUB_SPORTS,
  FETCH_CLUB_TRAINERS
} from 'src/constants';

export const fetchClubsAction = payload => dispatch => {
  dispatch({ type: FETCH_CLUBS, payload });
};

export const fetchClubBranchesAction = payload => dispatch => {
  dispatch({ type: FETCH_CLUB_BRANCHES, payload });
};

export const fetchClubSportsAction = payload => dispatch => {
  dispatch({ type: FETCH_CLUB_SPORTS, payload });
};

export const fetchClubTrainersAction = payload => dispatch => {
  dispatch({ type: FETCH_CLUB_TRAINERS, payload });
};
