import { CLOSE_DIALOG, DIALOG_LOADING, OPEN_DIALOG } from 'src/constants';

const INITIAL_STATE = {
  type: '',
  title: '',
  message: '',
  selectedItem: null,
  isOpen: false,
  isLoading: false
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case OPEN_DIALOG:
      return { ...state, ...payload, isOpen: true };
    case CLOSE_DIALOG:
      return { ...INITIAL_STATE };
    case DIALOG_LOADING:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};
