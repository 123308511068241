import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import {
  colors,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@material-ui/core';
import ButtonWithLoader from './ButtonWithLoader';
import {
  DELETE_CERTIFICATE,
  DELETE_CLUB,
  DELETE_CLUB_BRANCH,
  DELETE_CLUB_SPORT,
  DELETE_CLUB_TRAINER,
  DELETE_FAQ,
  DELETE_OFFER,
  DELETE_PAGE,
  DELETE_ROLE,
  DELETE_SLIDER,
  DELETE_SPECIAL_AD,
  DELETE_SPONSOR,
  DELETE_SPORT,
  DELETE_TRAINER,
  DELETE_TRAINER_SPORT
} from 'src/constants/dialogTypes';
import {
  deleteCertificate,
  deleteClub,
  deleteClubBranch,
  deleteClubSport,
  deleteClubTrainer,
  deleteFAQ,
  deleteOffer,
  deletePage,
  deleteRole,
  deleteSlider,
  deleteSpecialAd,
  deleteSponsor,
  deleteSport,
  deleteTrainer,
  deleteTrainerSport,
  fetchCertificates,
  fetchClubBranches,
  fetchClubs,
  fetchClubSports,
  fetchClubTrainers,
  fetchFAQs,
  fetchOffers,
  fetchPages,
  fetchSliders,
  fetchSpecialAds,
  fetchSponsors,
  fetchSports,
  fetchTrainers,
  fetchTrainerSports
} from 'src/api';
import {
  closeDialogAction,
  dialogLoadingAction,
  fetchClubsAction,
  fetchRolesAction,
  fetchSportsAction,
  fetchTrainersAction,
  fetchCertificatesAction,
  fetchClubSportsAction,
  fetchClubTrainersAction,
  fetchClubBranchesAction,
  fetchTrainerSportsAction,
  fetchSlidersAction,
  fetchSponsorsAction,
  fetchFAQsAction,
  fetchSpecialAdsAction,
  fetchOffersAction,
  fetchPagesAction
} from 'src/store/actions';
import schema from 'src/translate/english-schema.json';
import { useHistory, useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { getQueryParams } from 'src/utils/queryParams';

const dialogSchema = schema.role.delete.dialog;

const Dialog = ({
  dialog,
  closeDialogAction,
  dialogLoadingAction,
  fetchRolesAction,
  fetchSportsAction,
  fetchClubsAction,
  fetchTrainersAction,
  fetchCertificatesAction,
  fetchClubSportsAction,
  fetchClubTrainersAction,
  fetchClubBranchesAction,
  fetchTrainerSportsAction,
  fetchSlidersAction,
  fetchSponsorsAction,
  fetchFAQsAction,
  fetchSpecialAdsAction,
  fetchOffersAction,
  fetchPagesAction
}) => {
  const history = useHistory();
  const location = useLocation();
  const { page } = getQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    closeDialogAction();
  };

  const handlePaginate = (data, page) => {
    history.push({
      pathname: location.pathname,
      search: `page=${
        data.data.current_page > data.data.last_page
          ? data.data.last_page - 1
          : data.data.current_page - 1
      }`
    });
  };

  const handleAccept = async () => {
    try {
      switch (dialog.type) {
        case DELETE_ROLE: {
          dialogLoadingAction(true);
          const { data } = await deleteRole(dialog.selectedItem.id);
          fetchRolesAction(data.data);
          handlePaginate(data);
          break;
        }
        case DELETE_SPORT: {
          dialogLoadingAction(true);
          await deleteSport(dialog.selectedItem.id);
          const { data } = await fetchSports(parseInt(page || 0, 10) + 1);
          fetchSportsAction(data.data);
          handlePaginate(data.data);
          break;
        }
        case DELETE_CLUB: {
          dialogLoadingAction(true);
          await deleteClub(dialog.selectedItem.id);
          const { data } = await fetchClubs(parseInt(page || 0, 10) + 1);
          fetchClubsAction(data?.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_TRAINER: {
          dialogLoadingAction(true);
          await deleteTrainer(dialog.selectedItem.id);
          const { data } = await fetchTrainers(parseInt(page || 0, 10) + 1);
          fetchTrainersAction(data.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_CERTIFICATE: {
          dialogLoadingAction(true);
          await deleteCertificate(dialog.selectedItem.id);
          const { data } = await fetchCertificates(
            parseInt(page || 0, 10) + 1,
            dialog.selectedItem.trainer.id
          );
          fetchCertificatesAction(data.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_CLUB_SPORT: {
          const { clubId, id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteClubSport(id);
          const { data } = await fetchClubSports(
            parseInt(page || 0, 10) + 1,
            clubId
          );
          fetchClubSportsAction(data?.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_CLUB_TRAINER: {
          const { clubId, id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteClubTrainer(id);
          const { data } = await fetchClubTrainers(
            parseInt(page || 0, 10) + 1,
            clubId
          );
          fetchClubTrainersAction(data.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_CLUB_BRANCH: {
          const { clubId, id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteClubBranch(id);
          const { data } = await fetchClubBranches(
            parseInt(page || 0, 10) + 1,
            clubId
          );
          fetchClubBranchesAction(data.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_TRAINER_SPORT: {
          const { trainerId, id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteTrainerSport(id);
          const { data } = await fetchTrainerSports(
            parseInt(page || 0, 10) + 1,
            trainerId
          );
          fetchTrainerSportsAction(data?.data);
          handlePaginate({ data: data?.data?.meta });
          break;
        }
        case DELETE_SLIDER: {
          const { id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteSlider(id);
          const { data } = await fetchSliders(parseInt(page || 0, 10) + 1);
          fetchSlidersAction(data?.data);
          handlePaginate(data);
          break;
        }
        case DELETE_SPONSOR: {
          const { id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteSponsor(id);
          const { data } = await fetchSponsors(parseInt(page || 0, 10) + 1);
          fetchSponsorsAction(data?.data);
          handlePaginate(data);
          break;
        }
        case DELETE_FAQ: {
          const { id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteFAQ(id);
          const { data } = await fetchFAQs(parseInt(page || 0, 10) + 1);
          fetchFAQsAction(data?.data);
          handlePaginate(data);
          break;
        }
        case DELETE_SPECIAL_AD: {
          const { id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteSpecialAd(id);
          const { data } = await fetchSpecialAds(parseInt(page || 0, 10) + 1);
          fetchSpecialAdsAction(data?.data);
          handlePaginate(data);
          break;
        }
        case DELETE_OFFER: {
          const { id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deleteOffer(id);
          const { data } = await fetchOffers(parseInt(page || 0, 10) + 1);
          fetchOffersAction(data?.data);
          handlePaginate(data);
          break;
        }
        case DELETE_PAGE: {
          const { id } = dialog.selectedItem;
          dialogLoadingAction(true);
          await deletePage(id);
          const { data } = await fetchPages(parseInt(page || 0, 10) + 1);
          fetchPagesAction(data?.data);
          handlePaginate(data);
          break;
        }
        default:
          break;
      }
      closeDialogAction();
      enqueueSnackbar(schema.confirm.delete, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(schema.error.general, {
        variant: 'error'
      });
      closeDialogAction();
    }
  };

  return (
    <MUIDialog onClose={handleClose} open={dialog.isOpen} fullWidth>
      <DialogTitle>
        <Typography variant="h4">{dialog.title}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: '30px' }}>
        <Typography variant="body1">{dialog.message}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>{dialogSchema.cancel}</Button>
        <ButtonWithLoader
          variant="contained"
          title={dialogSchema.yes}
          color="secondary"
          style={{
            backgroundColor: colors.red[600],
            color: 'white'
          }}
          isLoading={dialog.isLoading}
          onClick={handleAccept}
        />
      </DialogActions>
    </MUIDialog>
  );
};

const mapStateToProps = ({ dialog }) => ({
  dialog
});

export default connect(mapStateToProps, {
  closeDialogAction,
  dialogLoadingAction,
  fetchRolesAction,
  fetchSportsAction,
  fetchClubsAction,
  fetchTrainersAction,
  fetchCertificatesAction,
  fetchClubSportsAction,
  fetchClubTrainersAction,
  fetchClubBranchesAction,
  fetchTrainerSportsAction,
  fetchSlidersAction,
  fetchSponsorsAction,
  fetchFAQsAction,
  fetchSpecialAdsAction,
  fetchOffersAction,
  fetchPagesAction
})(Dialog);
