// direct path to pages

// role
export const listRolePath = '/app/roles';
export const createRolePath = '/app/roles/create';
export const editRolePath = '/app/roles/:roleId/edit';
export const viewRolePath = '/app/roles/:roleId/view';

// user
export const profilePath = '/app/user/profile';

// club
export const listClubPath = '/app/clubs';
export const createClubPath = '/app/clubs/create';
export const editClubPath = '/app/clubs/:clubId/edit';
export const viewClubPath = '/app/clubs/:clubId/view';

// sport
export const listSportPath = '/app/sports';
export const createSportPath = '/app/sports/create';
export const editSportPath = '/app/sports/:sportId/edit';
export const viewSportPath = '/app/sports/:sportId/view';

// trainer
export const listTrainerPath = '/app/trainers';
export const createTrainerPath = '/app/trainers/create';
export const editTrainerPath = '/app/trainers/:trainerId/edit';
export const viewTrainerPath = '/app/trainers/:trainerId/view';
// contact us
export const listContactUsPath = '/app/contact-us';
export const viewContactUsPath = '/app/contact-us/:contactId/view';

// club trainer
export const listClubTrainerPath = '/app/club/:clubId/trainers';
export const createClubTrainerPath = '/app/club/:clubId/trainers/create';
export const editClubTrainerPath = '/app/club/:clubId/trainers/:trainerId/edit';
export const viewClubTrainerPath = '/app/club/:clubId/trainers/:trainerId/view';

// club sport
export const listClubSportPath = '/app/club/:clubId/sports';
export const createClubSportPath = '/app/club/:clubId/sports/create';
export const editClubSportPath = '/app/club/:clubId/sports/:sportId/edit';
export const viewClubSportPath = '/app/club/:clubId/sports/:sportId/view';

// club branch
export const listClubBranchPath = '/app/club/:clubId/branches';
export const createClubBranchPath = '/app/club/:clubId/branches/create';
export const editClubBranchPath = '/app/club/:clubId/branches/:branchId/edit';
export const viewClubBranchPath = '/app/club/:clubId/branches/:branchId/view';

// trainer certificate
export const listCertificatePath = '/app/trainers/:trainerId/certificates';
export const createCertificatePath =
  '/app/trainers/:trainerId/certificates/create';
export const editCertificatePath =
  '/app/trainers/:trainerId/certificates/:certificateId/edit';
export const viewCertificatePath =
  '/app/trainers/:trainerId/certificates/:certificateId/view';

// trainer sport
export const listTrainerSportPath = '/app/trainer/:trainerId/sports';
export const createTrainerSportPath = '/app/trainer/:trainerId/sports/create';
export const editTrainerSportPath =
  '/app/trainer/:trainerId/sports/:sportId/edit';
export const viewTrainerSportPath =
  '/app/trainer/:trainerId/sports/:sportId/view';

// settings
export const websiteSettings = '/app/settings';

// sliders
export const listSlidersPath = '/app/sliders';
export const createSliderPath = '/app/sliders/create';
export const editSliderPath = '/app/sliders/:sliderId/edit';
export const viewSliderPath = '/app/sliders/:sliderId/view';

// sponsors
export const listSponsorsPath = '/app/sponsors';
export const createSponsorPath = '/app/sponsors/create';
export const editSponsorPath = '/app/sponsors/:sponsorId/edit';
export const viewSponsorPath = '/app/sponsors/:sponsorId/view';

// special-ads
export const listSpecialAdsPath = '/app/special-ads';
export const createSpecialAdPath = '/app/special-ads/create';
export const editSpecialAdPath = '/app/special-ads/:specialAdId/edit';
export const viewSpecialAdPath = '/app/special-ads/:specialAdId/view';

// faqs
export const listFAQsPath = '/app/faqs';
export const editFAQsVideoPath = '/app/faqs/video';
export const createFAQPath = '/app/faqs/create';
export const editFAQPath = '/app/faqs/:FAQId/edit';
export const viewFAQPath = '/app/faqs/:FAQId/view';

// pages
export const listPagesPath = '/app/pages';
export const createPagePath = '/app/pages/create';
export const editPagePath = '/app/pages/:pageId/edit';
export const viewPagePath = '/app/pages/:pageId/view';

// pages
export const listOffersPath = '/app/offers';
export const createOfferPath = '/app/offers/create';
export const editOfferPath = '/app/offers/:offerId/edit';
export const viewOfferPath = '/app/offers/:offerId/view';

// function to get path with params
export const getEditRolePath = roleId => {
  return `/app/roles/${roleId}/edit`;
};

export const getEditClubPath = clubId => {
  return `/app/clubs/${clubId}/edit`;
};

export const getViewClubPath = clubId => {
  return `/app/clubs/${clubId}/view`;
};

export const getEditSportPath = sportId => {
  return `/app/sports/${sportId}/edit`;
};

export const getViewSportPath = sportId => {
  return `/app/sports/${sportId}/view`;
};

export const getEditTrainerPath = trainerId => {
  return `/app/trainers/${trainerId}/edit`;
};

export const getViewTrainerPath = trainerId => {
  return `/app/trainers/${trainerId}/view`;
};

export const getListCertificatePath = trainerId => {
  return `/app/trainers/${trainerId}/certificates`;
};

export const getCreateCertificatePath = trainerId => {
  return `/app/trainers/${trainerId}/certificates/create`;
};

export const getEditCertificatePath = (certificateId, trainerId) => {
  return `/app/trainers/${trainerId}/certificates/${certificateId}/edit`;
};

export const getViewCertificatePath = (certificateId, trainerId) => {
  return `/app/trainers/${trainerId}/certificates/${certificateId}/view`;
};

export const getListClubTrainerPath = clubId => {
  return `/app/club/${clubId}/trainers`;
};

export const getCreateClubTrainerPath = clubId => {
  return `/app/club/${clubId}/trainers/create`;
};

export const getEditClubTrainerPath = (trainerId, clubId) => {
  return `/app/club/${clubId}/trainers/${trainerId}/edit`;
};

export const getViewClubTrainerPath = (trainerId, clubId) => {
  return `/app/club/${clubId}/trainers/${trainerId}/view`;
};

export const getListClubSportPath = clubId => {
  return `/app/club/${clubId}/sports`;
};

export const getCreateClubSportPath = clubId => {
  return `/app/club/${clubId}/sports/create`;
};

export const getEditClubSportPath = (sportId, clubId) => {
  return `/app/club/${clubId}/sports/${sportId}/edit`;
};

export const getViewClubSportPath = (sportId, clubId) => {
  return `/app/club/${clubId}/sports/${sportId}/view`;
};

export const getListClubBranchPath = clubId => {
  return `/app/club/${clubId}/branches`;
};

export const getCreateClubBranchPath = clubId => {
  return `/app/club/${clubId}/branches/create`;
};

export const getEditClubBranchPath = (branchId, clubId) => {
  return `/app/club/${clubId}/branches/${branchId}/edit`;
};

export const getViewClubBranchPath = (branchId, clubId) => {
  return `/app/club/${clubId}/branches/${branchId}/view`;
};

export const getListTrainerSportPath = trainerId => {
  return `/app/trainer/${trainerId}/sports`;
};

export const getCreateTrainerSportPath = trainerId => {
  return `/app/trainer/${trainerId}/sports/create`;
};

export const getEditTrainerSportPath = (sportId, trainerId) => {
  return `/app/trainer/${trainerId}/sports/${sportId}/edit`;
};

export const getViewTrainerSportPath = (sportId, trainerId) => {
  return `/app/trainer/${trainerId}/sports/${sportId}/view`;
};

export const getEditSliderPath = trainerId => {
  return `/app/sliders/${trainerId}/edit`;
};

export const getViewSliderPath = trainerId => {
  return `/app/sliders/${trainerId}/view`;
};

export const getEditSponsorPath = sponsorId => {
  return `/app/sponsors/${sponsorId}/edit`;
};

export const getViewSponsorPath = sponsorId => {
  return `/app/sponsors/${sponsorId}/view`;
};

export const getEditSpecialAdPath = specialAdId => {
  return `/app/special-ads/${specialAdId}/edit`;
};

export const getViewSpecialAdPath = specialAdId => {
  return `/app/special-ads/${specialAdId}/view`;
};

export const getEditFAQPath = FAQId => {
  return `/app/faqs/${FAQId}/edit`;
};

export const getViewFAQPath = FAQId => {
  return `/app/faqs/${FAQId}/view`;
};

export const getEditOfferPath = offerId => {
  return `/app/offers/${offerId}/edit`;
};

export const getViewOfferPath = offerId => {
  return `/app/offers/${offerId}/view`;
};

export const getEditPagePath = pageId => {
  return `/app/pages/${pageId}/edit`;
};

export const getViewPagePath = pageId => {
  return `/app/pages/${pageId}/view`;
};

export const getViewContactUsPath = contactId => {
  return `/app/contact-us/${contactId}/view`;
};
