import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchOffers = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/special-offers?page=${page}&title=${query || ''}`
  );
};

export const getOffer = offerId => {
  return axiosInstance.get(`${BASE_URL}/api/special-offers/${offerId}`);
};

export const createOffer = offer => {
  return axiosInstance.post(`${BASE_URL}/api/special-offers`, offer);
};

export const updateOffer = (id, offer) => {
  return axiosInstance.post(`${BASE_URL}/api/special-offers/${id}`, offer);
};

export const deleteOffer = offerId => {
  return axiosInstance.delete(`${BASE_URL}/api/special-offers/${offerId}`);
};
