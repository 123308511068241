import { combineReducers } from 'redux';
import dialog from './dialog';
import role from './role';
import sport from './sport';
import club from './club';
import trainer from './trainer';
import certificate from './certificate';
import clubSport from './clubSport';
import clubTrainer from './clubTrainer';
import clubBranch from './clubBranch';
import area from './area';
import trainerSport from './trainerSport';
import governorate from './governorate';
import city from './city';
import slider from './slider';
import sponsor from './sponsor';
import faq from './faq';
import specialAds from './specialAds';
import offer from './offer';
import page from './page';

const appReducer = combineReducers({
  dialog,
  role,
  sport,
  club,
  trainer,
  certificate,
  clubSport,
  clubTrainer,
  clubBranch,
  area,
  trainerSport,
  governorate,
  city,
  slider,
  sponsor,
  faq,
  specialAds,
  offer,
  page
});

const rootReducer = (state, action) => {
  if (action.type === 'SESSION_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
