import { FETCH_SPONSORS } from 'src/constants';

const INITIAL_STATE = {
  data: null
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SPONSORS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
