import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import {
  createCertificatePath,
  createClubBranchPath,
  createClubPath,
  createClubSportPath,
  createClubTrainerPath,
  createFAQPath,
  createOfferPath,
  createPagePath,
  createRolePath,
  createSliderPath,
  createSpecialAdPath,
  createSponsorPath,
  createSportPath,
  createTrainerPath,
  createTrainerSportPath,
  editCertificatePath,
  editClubBranchPath,
  editClubPath,
  editClubSportPath,
  editClubTrainerPath,
  editFAQPath,
  editOfferPath,
  editPagePath,
  editRolePath,
  editSliderPath,
  editSpecialAdPath,
  editSponsorPath,
  editSportPath,
  editTrainerPath,
  editTrainerSportPath,
  listCertificatePath,
  listClubBranchPath,
  listClubPath,
  listClubSportPath,
  listClubTrainerPath,
  listContactUsPath,
  listFAQsPath,
  listOffersPath,
  listPagesPath,
  listRolePath,
  listSlidersPath,
  listSpecialAdsPath,
  listSponsorsPath,
  listSportPath,
  listTrainerPath,
  listTrainerSportPath,
  viewContactUsPath,
  websiteSettings
} from './constants';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/Login'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: listRolePath,
        component: lazy(() => import('src/views/role/List'))
      },
      {
        exact: true,
        path: createRolePath,
        component: lazy(() => import('src/views/role/Create'))
      },
      {
        exact: true,
        path: editRolePath,
        component: lazy(() => import('src/views/role/Create'))
      },
      {
        exact: true,
        path: listSportPath,
        component: lazy(() => import('src/views/sport/List'))
      },
      {
        exact: true,
        path: createSportPath,
        component: lazy(() => import('src/views/sport/Create'))
      },
      {
        exact: true,
        path: editSportPath,
        component: lazy(() => import('src/views/sport/Create'))
      },
      {
        exact: true,
        path: listClubPath,
        component: lazy(() => import('src/views/club/List'))
      },
      {
        exact: true,
        path: createClubPath,
        component: lazy(() => import('src/views/club/Create'))
      },
      {
        exact: true,
        path: editClubPath,
        component: lazy(() => import('src/views/club/Create'))
      },
      {
        exact: true,
        path: listTrainerPath,
        component: lazy(() => import('src/views/trainer/List'))
      },
      {
        exact: true,
        path: createTrainerPath,
        component: lazy(() => import('src/views/trainer/Create'))
      },
      {
        exact: true,
        path: editTrainerPath,
        component: lazy(() => import('src/views/trainer/Create'))
      },
      {
        exact: true,
        path: listCertificatePath,
        component: lazy(() => import('src/views/certificate/List'))
      },
      {
        exact: true,
        path: createCertificatePath,
        component: lazy(() => import('src/views/certificate/Create'))
      },
      {
        exact: true,
        path: editCertificatePath,
        component: lazy(() => import('src/views/certificate/Create'))
      },
      {
        exact: true,
        path: listClubSportPath,
        component: lazy(() => import('src/views/club/sport/List'))
      },
      {
        exact: true,
        path: createClubSportPath,
        component: lazy(() => import('src/views/club/sport/Create'))
      },
      {
        exact: true,
        path: editClubSportPath,
        component: lazy(() => import('src/views/club/sport/Create'))
      },
      {
        exact: true,
        path: listClubTrainerPath,
        component: lazy(() => import('src/views/club/trainer/List'))
      },
      {
        exact: true,
        path: createClubTrainerPath,
        component: lazy(() => import('src/views/club/trainer/Create'))
      },
      {
        exact: true,
        path: editClubTrainerPath,
        component: lazy(() => import('src/views/club/trainer/Create'))
      },
      {
        exact: true,
        path: listClubBranchPath,
        component: lazy(() => import('src/views/club/branch/List'))
      },
      {
        exact: true,
        path: createClubBranchPath,
        component: lazy(() => import('src/views/club/branch/Create'))
      },
      {
        exact: true,
        path: editClubBranchPath,
        component: lazy(() => import('src/views/club/branch/Create'))
      },
      {
        exact: true,
        path: listTrainerSportPath,
        component: lazy(() => import('src/views/trainer/sport/List'))
      },
      {
        exact: true,
        path: createTrainerSportPath,
        component: lazy(() => import('src/views/trainer/sport/Create'))
      },
      {
        exact: true,
        path: editTrainerSportPath,
        component: lazy(() => import('src/views/trainer/sport/Create'))
      },
      {
        exact: true,
        path: websiteSettings,
        component: lazy(() => import('src/views/settings'))
      },
      {
        exact: true,
        path: createSliderPath,
        component: lazy(() => import('src/views/slider/Create'))
      },
      {
        exact: true,
        path: listSlidersPath,
        component: lazy(() => import('src/views/slider/List'))
      },
      {
        exact: true,
        path: editSliderPath,
        component: lazy(() => import('src/views/slider/Create'))
      },
      {
        exact: true,
        path: createSponsorPath,
        component: lazy(() => import('src/views/sponsor/Create'))
      },
      {
        exact: true,
        path: listSponsorsPath,
        component: lazy(() => import('src/views/sponsor/List'))
      },
      {
        exact: true,
        path: editSponsorPath,
        component: lazy(() => import('src/views/sponsor/Create'))
      },
      {
        exact: true,
        path: createSpecialAdPath,
        component: lazy(() => import('src/views/ads/Create'))
      },
      {
        exact: true,
        path: listSpecialAdsPath,
        component: lazy(() => import('src/views/ads/List'))
      },
      {
        exact: true,
        path: editSpecialAdPath,
        component: lazy(() => import('src/views/ads/Create'))
      },
      {
        exact: true,
        path: createFAQPath,
        component: lazy(() => import('src/views/faq/Create'))
      },
      {
        exact: true,
        path: listFAQsPath,
        component: lazy(() => import('src/views/faq/List'))
      },
      {
        exact: true,
        path: editFAQPath,
        component: lazy(() => import('src/views/faq/Create'))
      },
      {
        exact: true,
        path: createPagePath,
        component: lazy(() => import('src/views/page/Create'))
      },
      {
        exact: true,
        path: listPagesPath,
        component: lazy(() => import('src/views/page/List'))
      },
      {
        exact: true,
        path: editPagePath,
        component: lazy(() => import('src/views/page/Create'))
      },
      {
        exact: true,
        path: createOfferPath,
        component: lazy(() => import('src/views/offer/Create'))
      },
      {
        exact: true,
        path: listOffersPath,
        component: lazy(() => import('src/views/offer/List'))
      },
      {
        exact: true,
        path: editOfferPath,
        component: lazy(() => import('src/views/offer/Create'))
      },
      {
        exact: true,
        path: listContactUsPath,
        component: lazy(() => import('src/views/contact-us/List'))
      },
      {
        exact: true,
        path: viewContactUsPath,
        component: lazy(() => import('src/views/contact-us/Details'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: DashboardLayout,
    routes: [
      {
        path: '/',
        exact: true,
        guard: AuthGuard,
        component: lazy(() => import('src/views/role/List'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
