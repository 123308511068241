export const APP_VERSION = '1.0.0';
export * from './paths';
export * from './actionTypes';
export * from './dialogTypes';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};
