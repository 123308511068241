import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchSliders = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/sliders?page=${page}&title=${query || ''}`
  );
};

export const getSlider = sliderId => {
  return axiosInstance.get(`${BASE_URL}/api/sliders/${sliderId}`);
};

export const createSlider = slider => {
  return axiosInstance.post(`${BASE_URL}/api/sliders`, slider);
};

export const updateSlider = (id, slider) => {
  return axiosInstance.post(`${BASE_URL}/api/sliders/${id}`, slider);
};

export const deleteSlider = sliderId => {
  return axiosInstance.delete(`${BASE_URL}/api/sliders/${sliderId}`);
};
