import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchSponsors = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/sponsers?page=${page}`
  );
};

export const getSponsor = sponsorId => {
  return axiosInstance.get(`${BASE_URL}/api/sponsers/${sponsorId}`);
};

export const createSponsor = sponsor => {
  return axiosInstance.post(`${BASE_URL}/api/sponsers`, sponsor);
};

export const updateSponsor = (id, sponsor) => {
  return axiosInstance.post(`${BASE_URL}/api/sponsers/${id}`, sponsor);
};

export const deleteSponsor = sponsorId => {
  return axiosInstance.delete(`${BASE_URL}/api/sponsers/${sponsorId}`);
};
