import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

const ButtonWithLoader = props => {
  const { title, isLoading } = props;

  return (
    <>
      <Button {...props} disabled={isLoading} style={{ borderRadius: '7px' }}>
        {!isLoading && title}
        {isLoading && <CircularProgress color="light" size={24} />}
      </Button>
    </>
  );
};

export default ButtonWithLoader;
