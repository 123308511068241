import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchClubSports = (page = 1, clubId) => {
  const url = clubId
    ? `${BASE_URL}/api/club/${clubId}/sports`
    : `${BASE_URL}/api/club/sports`;
  return axiosInstance.get(`${url}?page=${page}`);
};

export const getClubSport = sportId => {
  return axiosInstance.get(`${BASE_URL}/api/club/sports/${sportId}`);
};

export const createClubSport = sport => {
  if (!sport.age_to) delete sport.age_to;
  return axiosInstance.post(`${BASE_URL}/api/club/sports`, sport);
};

export const updateClubSport = sport => {
  if (!sport.age_to) delete sport.age_to;
  return axiosInstance.post(`${BASE_URL}/api/club/sports`, sport);
};

export const deleteClubSport = sportId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/clubs/sports/${sportId}?page=${parseInt(page || 1, 10) +
      1}`
  );
};
