import React from 'react';

const Logo = props => {
  return (
    <img
      alt="Logo"
      src={props.dark ? '/static/logo.png' : '/static/logo-white.png'}
      {...props}
    />
  );
};

export default Logo;
