import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchSpecialAds = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/special-ads?page=${page}&title=${query || ''}`
  );
};

export const getSpecialAd = specialAdId => {
  return axiosInstance.get(`${BASE_URL}/api/special-ads/${specialAdId}`);
};

export const createSpecialAd = specialAd => {
  return axiosInstance.post(`${BASE_URL}/api/special-ads`, specialAd);
};

export const updateSpecialAd = (id, specialAd) => {
  return axiosInstance.post(`${BASE_URL}/api/special-ads/${id}`, specialAd);
};

export const deleteSpecialAd = specialAdId => {
  return axiosInstance.delete(`${BASE_URL}/api/special-ads/${specialAdId}`);
};
