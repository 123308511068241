import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchFAQs = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/admin/faqs?page=${page}&question=${query || ''}`
  );
};

export const getFAQ = faqId => {
  return axiosInstance.get(`${BASE_URL}/api/admin/faqs/${faqId}`);
};

export const createFAQ = faq => {
  return axiosInstance.post(`${BASE_URL}/api/admin/faqs`, faq);
};

export const updateFAQ = (id, faq) => {
  return axiosInstance.patch(`${BASE_URL}/api/admin/faqs/${id}`, faq);
};

export const deleteFAQ = faqId => {
  return axiosInstance.delete(`${BASE_URL}/api/admin/faqs/${faqId}`);
};
