import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';
import { getQueryParams } from 'src/utils/queryParams';

export const fetchRoles = (page = 1) => {
  return axiosInstance.get(`${BASE_URL}/api/roles?page=${page}`);
};

export const fetchPermissions = () => {
  return axiosInstance.get(`${BASE_URL}/api/permissions`);
};

export const getRole = roleId => {
  return axiosInstance.get(`${BASE_URL}/api/roles/${roleId}`);
};

export const createRole = role => {
  return axiosInstance.post(`${BASE_URL}/api/roles`, role);
};

export const updateRole = role => {
  return axiosInstance.patch(`${BASE_URL}/api/roles/${role.id}`, role);
};

export const deleteRole = roleId => {
  const { page } = getQueryParams();
  return axiosInstance.delete(
    `${BASE_URL}/api/roles/${roleId}?page=${parseInt(page || 1, 10) + 1}`
  );
};
