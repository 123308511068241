import { CLOSE_DIALOG, DIALOG_LOADING, OPEN_DIALOG } from 'src/constants';

export const openDialogAction = payload => dispatch => {
  dispatch({ type: OPEN_DIALOG, payload });
};

export const closeDialogAction = () => dispatch => {
  dispatch({ type: CLOSE_DIALOG });
};

export const dialogLoadingAction = payload => dispatch => {
  dispatch({ type: DIALOG_LOADING, payload });
};
