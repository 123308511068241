import { BASE_URL } from 'src/config';
import axiosInstance from 'src/utils/axios';

export const fetchPages = (page = 1, query) => {
  return axiosInstance.get(
    `${BASE_URL}/api/pages?page=${page}&title=${query || ''}`
  );
};

export const getPage = pageId => {
  return axiosInstance.get(`${BASE_URL}/api/pages/${pageId}`);
};

export const createPage = page => {
  return axiosInstance.post(`${BASE_URL}/api/pages`, page);
};

export const updatePage = (id, page) => {
  return axiosInstance.post(`${BASE_URL}/api/pages`, page);
};

export const deletePage = pageId => {
  return axiosInstance.delete(`${BASE_URL}/api/pages/${pageId}`);
};
