export function getQueryParams() {
  const params = {};
  const searchParams = new URLSearchParams(window.location.search);

  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  return params;
}

export function objectToQueryParams(obj) {
  const queryParams = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // Skip adding parameters with undefined or null values
      if (value !== undefined && value !== null) {
        // If the value is an array, create multiple parameters with the same key
        if (Array.isArray(value)) {
          value.forEach((item) => queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`));
        } else {
          queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
      }
    }
  }
  return queryParams.join('&');
}
